import React from "react";
import { Link } from "gatsby";
import Button from "../components/button";
import SectionHeader from "./section-header";

const CallToAction = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="Mantenete actualizado"
      description="La tranquilidad de administrar tu feedlot desde la palma de tu mano."
    />
    <Link to="https://app.miscorrales.com" target="_blank">
      <Button>Iniciar sesión</Button>
    </Link>
  </div>
);

export default CallToAction;
