import React from "react";

import feature from "../images/feature.png";
import SectionHeader from "./section-header";
import { COLORS } from "../styles/constants";

const Content = () => (
  <div style={{ padding: "4rem 1rem", textAlign: "center" }}>
    <SectionHeader
      title="En tiempo real"
      description="Poder tomar desiciones a tiempo ahora es posible."
    />
    <content
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 340px))",
      }}
    >
      <div>
        <h3>El panel de control más intuitivo y completo</h3>
        <p style={{ color: COLORS.gray }}>
          Configuración de dietas, corrales, tropas, reportes de producción,
          etc.
        </p>
      </div>
      <div>
        <img
          src={feature}
          alt="Registro por corrales, dietas, consumo y costos. Armado de órdenes de trabajo y ejecución diaria"
        />
      </div>
    </content>
  </div>
);

export default Content;
