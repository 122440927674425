import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "mockup-content.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    `}
    render={(data) => (
      <GatsbyImage
        image={data.file.childImageSharp.gatsbyImageData}
        alt="Programa para feedlot"
      />
    )}
  />
);
export default Image;
