import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Button from "../components/button";
import headerImage from "../images/header.png";
import MockupContent from "./image";
import mockupFrame from "../images/mockup-frame.png";

const androidUrl =
  "https://play.google.com/store/apps/details?id=com.matiasniz.miscorrales&hl=es&gl=US";

const Header = ({ siteTitle }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      padding: "4rem 1rem",
    }}
  >
    <div
      style={{
        backgroundImage: `url(${headerImage})`,
        position: "absolute",
        top: 0,
        zIndex: -5,
        height: "100vh",
        width: "100vw",
        opacity: 0.5,
      }}
    />
    <h1 style={{ textAlign: "center" }}>{siteTitle}</h1>
    <p style={{ textAlign: "center", maxWidth: 440 }}>
      Primer sistema inteligente para automatizar la alimentación en corrales de
      engorde.
    </p>
    <Link to="https://app.miscorrales.com" target="_blank">
      <Button>Iniciar sesión</Button>
    </Link>
    <div style={{ margin: 60, width: `250px`, position: "relative" }}>
      <div style={{ clipPath: "inset(2% 5% round 2% 5%)" }}>
        <MockupContent />
      </div>
      <div
        style={{
          position: "absolute",
          width: "250px",
          top: 0,
        }}
      >
        <img
          src={mockupFrame}
          alt="Mis Corrales - Administración de feedlots"
        />
      </div>
    </div>

    <div>
      <Link to={androidUrl} target="_blank">
        <div
          style={{
            background:
              "url(https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png) 0% 0% / contain no-repeat",
            display: "inline-block",
            overflow: "hidden",
            textDecoration: "none",
            height: "100px",
            width: "200px",
            padding: "5px",
          }}
        ></div>
      </Link>
    </div>
  </div>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: `Software para feedlots`,
};

export default Header;
